.painel {
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: normal;
  background-image: url("../../../img/fundoLogar02.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
